import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="durchblutungsstoerungen" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Durchblutungsstörung`}</h1>
      <h3 {...{
        "id": "durchblutungsstorung"
      }}>{`Durchblutungsstörung`}</h3>
      <p>{`Eine alte Weisheit lautet: der Mensch ist so alt wie seine Gefäße! Wir haben es zum Teil selbst in der Hand, unsere Blutgefäße jung zu erhalten. Etliche Risikofaktoren für Gefäßveränderungen sind seit langem bekannt: hoher Blutdruck, Rauchen, Übergewicht, Diabetes, Bewegungsmangel, `}<a parentName="p" {...{
          "href": "/beschwerdebilder/erschoepfung_stress"
        }}>{`Erschöpfung / Stress`}</a>{`…`}</p>
      <p>{`Auch wenn der gute Wille häufig vorhanden ist, braucht so mancher ein wenig Hilfestellung bei der Korrektur ungünstiger Lebensgewohnheiten.`}</p>
      <p>{`Neben den Hilfen zur Lebensumgestaltung (Rauchentwöhnung, Gewichtsreduktion, Ernährungs- und Bewegungsberatung…) bietet die Naturheilkunde eine Fülle pflanzlicher und homöopathischer Medikamente zur Gefäßpflege.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      